@font-face {
    font-family: Gilroy;
    font-weight: 100;
    src: url("Gilroy/Gilroy-Thin.otf") format("opentype") ;
}

@font-face {
    font-family: Gilroy;
    font-weight: 100;
    font-style: italic;
    src: url("Gilroy/Gilroy-ThinItalic.otf") format("opentype") ;
}

@font-face {
    font-family: Gilroy;
    font-weight: 200;
    src: url("Gilroy/Gilroy-UltraLight.otf") format("opentype") ;
}

@font-face {
    font-family: Gilroy;
    font-weight: 200;
    font-style: italic;
    src: url("Gilroy/Gilroy-UltraLightItalic.otf") format("opentype") ;
}

@font-face {
    font-family: Gilroy;
    font-weight: 300;
    src: url("Gilroy/Gilroy-Light.otf") format("'opentype") ;
}

@font-face {
    font-family: Gilroy;
    font-weight: 300;
    font-style: italic;
    src: url("Gilroy/Gilroy-LightItalic.otf") format("'opentype") ;
}

@font-face {
    font-family: Gilroy;
    font-weight: 400;
    src: url("Gilroy/Gilroy-Regular.otf") format("'opentype") ;
}

@font-face {
    font-family: Gilroy;
    font-weight: 400;
    font-style: italic;
    src: url("Gilroy/Gilroy-RegularItalic.otf") format("'opentype") ;
}

@font-face {
    font-family: Gilroy;
    font-weight: 500;
    src: url("Gilroy/Gilroy-Medium.otf") format("'opentype") ;
}

@font-face {
    font-family: Gilroy;
    font-weight: 500;
    font-style: italic;
    src: url("Gilroy/Gilroy-MediumItalic.otf") format("'opentype") ;
}

@font-face {
    font-family: Gilroy;
    font-weight: 600;
    src: url("Gilroy/Gilroy-Semibold.otf") format("opentype") ;
}

@font-face {
    font-family: Gilroy;
    font-weight: 600;
    font-style: italic;
    src: url("Gilroy/Gilroy-SemiboldItalic.otf") format("opentype") ;
}

@font-face {
    font-family: Gilroy;
    font-weight: 700;
    src: url("Gilroy/Gilroy-Bold.otf") format("opentype") ;
}

@font-face {
    font-family: Gilroy;
    font-weight: 700;
    font-style: italic;
    src: url("Gilroy/Gilroy-BoldItalic.otf") format("opentype") ;
}

@font-face {
    font-family: Gilroy;
    font-weight: 800;
    src: url("Gilroy/Gilroy-Extrabold.otf") format("opentype") ;
}

@font-face {
    font-family: Gilroy;
    font-weight: 800;
    font-style: italic;
    src: url("Gilroy/Gilroy-ExtraboldItalic.otf") format("opentype") ;
}

@font-face {
    font-family: Gilroy;
    font-weight: 900;
    src: url("Gilroy/Gilroy-Black.otf") format("opentype") ;
}

@font-face {
    font-family: Gilroy;
    font-weight: 900;
    font-style: italic;
    src: url("Gilroy/Gilroy-BlackItalic.otf") format("opentype") ;
}